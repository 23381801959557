/* 
 * ---=== Checkbox Styles ===---
 */

.checkboxes {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
}

.checkbox {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
}

.checkbox-check {
    vertical-align: middle;
    top: 0.3em;
    box-shadow: none !important;
}

.checkbox-indent {
    margin-left: 20px;
}

.checkbox-label {
    display: flex;
    vertical-align: top;
    width: 235px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.checkbox-text {
    margin-left: 20px;
    vertical-align: top;
    display: inline-block;
    margin-top: 6px;
    font-size: 14px;
    color: #172B4D;
    width: 100%;
    word-wrap: break-word;
}

#first-checkbox {
    font-weight: bold;
}

#checkAll {
    font-weight: bold;
}

.list-heading-space {
    padding-bottom: 15px;
}

.number-circle {
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    background-color: #663399;
    color: #FFFFFF;
}

#save {
    margin-top: 0px;
    margin-bottom: 10px;
}

#save:active {
    background-color: #663399;
}

.orah-button {
    background-color: #663399;
    color: #ffffff;
    padding: .6em 2.2em;
    cursor: pointer;
}

button.orah-button:hover {
    background-color: #663399;
    color: #ffffff;
    padding: .6em 2.2em;
    opacity: 0.7;
}

button.orah-button:active {
    background-color: #663399;
}

button.orah-button:focus {
    background-color: #663399;
}

.onboarding-button {
    margin-left: 5px;
    margin-right: 5px;
}

#t-member {
    background: none;
    font-weight: bold;
}

.modal-columns {
    float: left;
    width: 40%;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 20px;
    padding-top: 20px;
}

#list-pick {
    background-color: #fcfffd;
    min-height: 270px;
}

.button-width {
    width: 180px;
    margin-bottom: 5px;
}

input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #555555;
    border-radius: 3px;
    background-color: white;
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

/* Export Format Selector */

#button-section {
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;

    text-align: center;
    align-items: center;
}

body {
    margin: 0;
    font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.3em;
}

.ui {
    min-height: 350px;
    grid-template-rows: 40px 50px 1fr;
    background-color: #F5F5F5;
    color: #eee;
    min-width: 300px;
    overflow: visible;
    display: inline-block;
    padding: 10px 10px 10px 0;
}

.navbar {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.navbar.app {
    background-color: #0067a3;
    font-size: 1.5rem;
}

.navbar.board {
    font-size: 1.1rem;
}

.lists {
    display: flex;
    overflow-x: auto;
}

.lists > * {
    flex: 0 0 auto;
    margin-left: 10px;
}

.lists::after {
    content: '';
    flex: 0 0 10px;
}

.list {
    width: 300px;
    height: calc(100% - 10px - 17px);
}

.list > * {
    background-color: #e2e4e6;
    color: #333;
    padding: 0 10px;
}

.list header {
    line-height: 36px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    word-wrap: break-word;
}

.list footer {
    line-height: 36px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #888;
}

.list ul {
    list-style: none;
    margin: 0;
    max-height: calc(100% - 36px - 36px);
    overflow-y: auto;
}

.list ul li {
    background-color: #fff;
    padding: 10px;
    border-radius: 3px;
}

.list ul li:not(:last-child) {
    margin-bottom: 10px;
}

.list ul li img {
    display: block;
    width: calc(100% + 2 * 10px);
    margin: -10px 0 10px -10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.evaluationMessagePNG {
    width: 600px;
    min-height: 250px;
    color: black;
    text-align: center;
    line-height: 1;
}